.app {
  max-width: 720px;
  margin: 0 auto;
}

.header {
  text-align: left;
}

.logo {
  width: 435px;
  max-width: 60%;
  @media (max-width: 740px) {
    margin-left:12px;
  }
}

.information {
  @media (max-width: 740px) {
    margin-left:12px;
    margin-right:12px;

  }
  :before {
    content: "\26A0";
    color: #dd2a30;
    font-size: 25px;
    left: 10px;
    top: 10px;
    position: absolute;
  }
  background-color: #fa61000d;
  margin: 16px 0;
  position: relative;
  padding: 16px 16px 16px 40px;
  font-size: 15px;
  font-family: Arial,Helvetica,Verdana,Geneva,sans-serif;
  line-height: 1.13;
  color: #222;
}
